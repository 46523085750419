import React, { useState } from "react";
import "./index.css";

const SmallPopupWithClose = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false); // Hide the popup
  };

  return (
    isVisible && (
      <div className="small-popup">
        <div className="popup-content">
          <p>
          Help us understand your challenges with cloud costs and AI model
          deployment. 
          </p>
          <button className="survey-btn" onClick={() => {
            window.open("https://forms.gle/ze6n57S8M29LYBa98", "_blank")
          }}>
            Take Survey
          </button>

          <button onClick={handleClose} className="close-btn">
            ✖
          </button>
        </div>
      </div>
    )
  );
};

export default SmallPopupWithClose;
