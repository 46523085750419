import React from 'react'
import './index.css'

const Blog = () => {
  return (
    <div className='blog-page'>
    <p className='blog-h'>Blog series 🗉</p>
    <div className="blog-list">
    <div className='blog'>
        <p className='p-h1'>Slash Cloud Costs with On-Prem AI Infrastructure Solutions</p>
        <p className='p-h2'> Author ~ Yash Patel</p>
        <a href='https://medium.com/@fordev951/slash-cloud-costs-with-on-prem-ai-infrastructure-solutions-fb827e313d4e' className='p-h3'>Read Here</a>
      </div>
      <div className='blog'>
        <p className='p-h1'> Introduction to Docker</p>
        <p className='p-h2'> Author ~ Yash Patel</p>
        <a href='https://medium.com/@fordev951/introduction-to-docker-7f0ccb18d944' className='p-h3'>Read Here</a>
      </div>

      <div className='blog'>
        <p className='p-h1'> Automating CD with AutoCD</p>
        <p className='p-h2'> Author ~ Yash Patel</p>
        <a href='https://medium.com/@fordev951/automating-cd-with-autocd-44adc1bad222' className='p-h3'>Read Here</a>
      </div>

    </div>
  </div>

  )
}

export default Blog