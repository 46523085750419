import axios from 'axios';
import { getHost } from '../utils/util';

const BACKEND_URL = getHost()


export const registerAppointment = async(name, email, organization, phone)=>{

    const url = `${BACKEND_URL}/api/user/regApmnt`;
    const response = await axios.post(url, {
        name: name, 
        email: email,
        phone: phone,
        organization: organization
    });
    return response.data;
}