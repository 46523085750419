
import React from 'react';
import './App.css';
import Home from './pages/home';
import {Toaster} from 'react-hot-toast';
import { Routes, Route } from 'react-router-dom';
import Appointment from './pages/appointment';
import ContactHeader from './components/contact-header';
import Footer from './components/footer';
import AboutUs from './pages/aboutUs';
import Blog from './pages/blogs';
const App=()=> {
  return (
    <>
    <Toaster/>
    <ContactHeader/>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/appointment" element={<Appointment />} />
    <Route path="/about-us" element={<AboutUs/>} />
    <Route path="/blogs" element={<Blog/>}/>
    </Routes>
    <Footer/>
   
    </>
  );
}

export default App;
