import React, {useState, useEffect } from 'react'
import './index.css'
import awslogo from '../../assets/logo/aws-logo.png'
import dockerlogo from '../../assets/logo/docker-logo.png'
import kubernetelogo from '../../assets/logo/kubernetes-logo.png'
import jenkinslogo from '../../assets/logo/jenkins-logo.png'
import prometheuslogo from '../../assets/logo/prometheus-logo.png'
import terrafomlogo from '../../assets/logo/terraform-logo.png'
import linuxlogo from '../../assets/logo/linux-logo.png'
import maasLogo from '../../assets/logo/maas-logo.png'
import cephLogo from '../../assets/logo/ceph-logo.png'
import openstackLogo from '../../assets/logo/openstack-logo.png'
import proxmoxLogo from '../../assets/logo/proxmox-logo.png'
import cudeLogo from '../../assets/logo/cuda-logo.png'
import dellLogo from '../../assets/logo/dell-logo.png'
import ciscoLogo from '../../assets/logo/cisco-logo.png'
import nvidiaLogo from '../../assets/logo/nvidai-logo.png'

import { Link } from 'react-router-dom'
import SmallPopupWithClose from '../../components/popup'


const Home = () => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set the text to be visible after component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to allow for transition effect

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <>
      
      <SmallPopupWithClose/>
      <div className='h1'>
        forDEV
      </div>
    
      <div className='h1-sub'>Future-Oriented Resource Development</div>
      <div className={`tran-h2 ${isVisible ? 'visible' : ''}`}>
        AI and IT Infrastructure Services and Solutions
      </div>

      <div className='sub-h2'>
        Frustated with skyrocketing  🚀 cloud costs? <br />
        Continous training, fine-tunnung and deploying 🤖 AI models on cloud is costing you a fortune 😭? <br />
        We have a solution for you 🤩. <br />
      </div>

      <div className='sub-h3'>
      💪Empowering AI with Scalable On-Premises Infrastructure,<br/>
      Use Open-source Software Solutions And Minimize Your Cost 🏆,<br/>
      Get 24/7 🛠️ Support <br/>
      Interested ? <Link to={"/appointment"} className='contact-button'>Book An Appointment</Link>

      <div className='sub-h4'>
        <Link className='help-btn' to={"/about-us"}>How We Help</Link>
      </div>
      </div>
      <div className='expertise'>
        <p className='h2'>Our Software Expertise</p>
        <div className='expertise-list'>
          <div className='grid-container'>
            <div className='logo' >
              <img src={awslogo} alt='aws-logo' />
            </div>
            <div className='logo' >
              <img src={dockerlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={kubernetelogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={linuxlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={jenkinslogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={prometheuslogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={terrafomlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={proxmoxLogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={openstackLogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={cephLogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={maasLogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={cudeLogo} alt='docker-logo' />
            </div>


          </div>
        </div>

      </div>

      <div className='expertise'>
        <p className='h2'>Infrastructure Using Top Hardware Vendors</p>
        <div className='expertise-list'>
          <div className='gridcontainer'>
            <div className='logo' >
              <img src={dellLogo} alt='aws-logo' />
            </div>
            <div className='logo' >
              <img src={nvidiaLogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={ciscoLogo} alt='docker-logo' />
            </div>
          

          </div>
        </div>

      </div>


      <div>
        <p className='h2'>Our Products</p>
        <div className="product-list">
          <div className='product'>
            <p className='p-h1'> Wharf</p>
            <p className='p-h2'> A tool to organize monolithic server</p>
            <a href='https://github.com/fordevio/wharf' className='p-h3'>Learn More</a>
          </div>
          <div className='product'>
            <p className='p-h1'> autoCD</p>
            <p className='p-h2'> Automating CICD in monolithic server</p>
            <a href='https://github.com/fordevio/autoCD' className='p-h3'>Learn More</a>
          </div>
          <div className='product'>
            <p className='p-h1'>Terraform Modules</p>
            <p className='p-h2'> A collection of terraform modules to make creation of infrastructure easy</p>
            <a href='https://github.com/orgs/fordevio/repositories' className='p-h3'>Learn More</a>
          </div>

        </div>
      </div>
     <div className='blog-btn-container'>
      <Link to={"/blogs"} className='blog-btn'>Blog Series</Link>
     </div>
    </>
  )
}

export default Home