import React, { useState } from 'react'
import './index.css'
import { registerAppointment } from '../../api/user';
import toast from 'react-hot-toast';
const Appointment = () => {
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [organization, setOrganization] = useState('');
      const [phone, setPhone] = useState('');
    
      const submitHandler = async () => {
        if (name === '' || email === '' || organization === '' || phone === '') {
          toast.error('All fields are required');
          return;
        }
        const func = async () => {
          await registerAppointment(name, email, organization, phone);
          setName('');
          setEmail('');
          setOrganization('');
          setPhone('');
        }
        toast.promise(
          func(),
          {
            loading: 'Registering...',
            success: <b>Registered for appointment!</b>,
            error: <b>Error occured</b>,
          }
        );
    
      }
  return (
    <div className='appointment'>

    <div className='form'>
    <p className='h2'>Book an appointment</p>
      <div className='input-box'>
        <span className='form-label'>Name*:    </span>
        <input type='text' onChange={(e) => setName(e.target.value)} className='form-input' value={name} />
      </div>
      <div className='input-box'>
        <span className='form-label'>Organization*:    </span>
        <input type='text' onChange={(e) => setOrganization(e.target.value)} className='form-input' value={organization} />
      </div>
      <div className='input-box'>
        <span className='form-label'>Email*:    </span>
        <input type='email' onChange={(e) => setEmail(e.target.value)} className='form-input' value={email} />
      </div>
      <div className='input-box'>
        <span className='form-label'>Number*:    </span>
        <input type='text' onChange={(e) => setPhone(e.target.value)} className='form-input' value={phone} />
      </div>
      <div className='input-box'>
        <button className='form-button' onClick={submitHandler}>Submit</button>
      </div>
    </div>
  
  
    
  </div>
  )
}

export default Appointment