import React from 'react'
import './index.css'

const AboutUs = () => {
  return (
    <div className='about-us-container'>
        <h1 className='a-h1'>How we can help you?</h1>
        <div className='help-list'>
         <div className='help'>
            <p className='a-h2'>✅ Creating on-premise AI infrastructure</p>
            <p className='a-h3'>Our team specializes in thoroughly researching your AI requirements, understanding your workloads, and designing a robust infrastructure in the least cost possible that aligns with your objectives. Leveraging industry-leading hardware from trusted brands like Cisco, Dell, and NVIDIA, we ensure that every component is optimized for high-performance AI workloads, including machine learning, deep learning, and data analytics.</p>
         </div>
         <div className='help'>
            <p className='a-h2'>✅ Use of opensource softwares</p>
            <p className='a-h3'>We will use opensource softwares above the infrastructure. Our approach not only reduces licensing fees but also provides flexibility and customization for your unique AI needs. Additionally, we offer comprehensive support for these open-source tools, ensuring that your operations remain smooth and productive. With our plug-and-play infrastructure, you can focus entirely on utilizing AI capabilities without worrying about setup or maintenance—we take care of everything, delivering a ready-to-use, high-performance solution tailored to your goals.</p>
         </div>
         <div className='help'>
            <p className='a-h2'>✅ Hybrid cloud setup</p>
            <p className='a-h3'>If you're currently relying on public cloud platforms, we can help you transition to a hybrid system where your major AI workloads run on-premises while retaining the flexibility of the cloud for specific tasks. This approach offers the best of both worlds—significant cost savings for compute-intensive AI workloads, enhanced data privacy, and reduced latency for critical operations, while still benefiting from the scalability and accessibility of the cloud for burst workloads or less sensitive tasks</p>
         </div>
         <div className='help'>
            <p className='a-h2'>✅ 24/7 support</p>
            <p className='a-h3'>We provide 24/7 support for your on-premises and hybrid AI infrastructure. Our dedicated team of experts is always available to monitor, troubleshoot, and resolve any issues, ensuring maximum uptime and performance. Whether it's maintaining your hardware, optimizing your open-source software stack, or addressing any operational challenges, we’re here to provide prompt and effective solutions around the clock.</p>
          </div>
        </div>
    </div>
  )
}

export default AboutUs